import './css/loader.css';
import React, { lazy, Suspense } from 'react';
import Loading from './components/Layouts/Loading';
import Security from './components/Server/Security';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Fonction lazyRetry pour garder le chargement asynchrone des composants avec réessai
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );

        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
                return window.location.reload();
            }
            reject(error);
        });
  });
};

const Login = lazy(() => lazyRetry(() => import('./components/Pages/Auth/Login/Index')));
const Register = lazy(() => lazyRetry(() => import('./components/Pages/Auth/Register/Index')));
const Forgot = lazy(() => lazyRetry(() => import('./components/Pages/Auth/Forgot/Index')));
const Wallet = lazy(() => lazyRetry(() => import('./components/Pages/Wallet/Index')));
const History = lazy(() => lazyRetry(() => import('./components/Pages/History/Index')));
const Resources = lazy(() => lazyRetry(() => import('./components/Pages/Resources/Index')));
const FirstTime = lazy(() => lazyRetry(() => import('./components/Pages/FirstTime/Index')));
// const Login = lazy(() => lazyRetry(() => import('./components/Pages/Login/Index')));
// const Logout = lazy(() => lazyRetry(() => import('./components/Pages/Logout')));

const Err404 = lazy(() => lazyRetry(() => import('./components/Pages/Error/Err404')));

class RouterApp extends React.Component {
  render() {
    return (
        <Router>
            <Suspense fallback={
                <Loading />
            }>
                <Routes>
                    {/* NO AUTHENTICATION REQUIRED START */}
                        <Route path="/" element={<Login showNavbar={false} />} />
                        <Route path="/login" element={<Login showNavbar={false} />} />
                        <Route path="/forgot" element={<Forgot showNavbar={false} />} />
                        <Route path="/forgot/:key" element={<Forgot showNavbar={false} />} />
                        <Route path="/register" element={<Register showNavbar={false} />} />
                        <Route path="/register/confirmation" element={<Register showNavbar={false} confirmation={true} />} />
                    {/* NO AUTHENTICATION REQUIRED END */}

                    {/* AUTHENTICATION REQUIRED START */}
                        <Route path="/first-time" element={
                            <Security>
                                <FirstTime />
                            </Security>
                        } />
                        <Route path="/wallet" element={
                            <Security>
                                <Wallet />
                            </Security>
                        } />
                        <Route path="/history" element={
                            <Security>
                                <History />
                            </Security>
                        } />
                        <Route path="/resources" element={
                            <Security>
                                <Resources />
                            </Security>
                        } />
                    {/* AUTHENTICATION REQUIRED END */}

                    {/* ERROR PAGE START */}
                        <Route path="*" element={<Err404 showNavbar={false} />} />
                    {/* ERROR PAGE END */}
                </Routes>
            </Suspense>
        </Router>
    );
  }
}

export default RouterApp;